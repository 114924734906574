import { improvedFetch } from "../../components/fetch";
import { FetchError } from "../errors";
import { FinancialHelp as FinancialHelp } from "./types";

export const getAllFinancialHelps = (): Promise<FinancialHelp[]> =>
  improvedFetch(`/financial-helps`).then(res => {
    if (!res.ok) {
      throw new FetchError();
    }
    return res.json();
  });

export const putFinancialHelp = ({
  uuid,
  ...data
}: Omit<FinancialHelp, "created_at" | "updated_at">): Promise<FinancialHelp> =>
  improvedFetch(`/financial-helps/${uuid}`, {
    method: "put",
    body: JSON.stringify(data),
  }).then(res => {
    if (!res.ok) {
      throw new FetchError();
    }
    return res.json();
  });

export const postFinancialHelp = (
  data: Omit<FinancialHelp, "uuid" | "created_at" | "updated_at">
): Promise<FinancialHelp> =>
  improvedFetch(`/financial-helps`, {
    method: "post",
    body: JSON.stringify(data),
  }).then(res => {
    if (!res.ok) {
      throw new FetchError();
    }
    return res.json();
  });
