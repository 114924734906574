import {
  Box,
  Flex,
  Stack,
  HStack,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Button,
  ButtonGroup,
  Text,
} from "@chakra-ui/react";
import { ReactNode } from "react";

import { FiSearch } from "react-icons/fi";

export const LayoutTable = ({
  title,
  withSearch,
  actions,
  table,
  withPagination,
}: {
  title: string;
  withSearch?: boolean;
  actions?: ReactNode;
  table: ReactNode;
  withPagination?: boolean;
}) => {
  return (
    <Flex direction={"column"} maxHeight={"100%"} gap={"4"}>
      <Box px="2" py="2">
        <Stack direction={{ base: "column", md: "row" }} justify="space-between">
          <Text textStyle="lg" fontWeight="medium">
            {title}
          </Text>

          <HStack>
            {withSearch ? (
              <InputGroup maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                </InputLeftElement>
                <Input placeholder="Search" />
              </InputGroup>
            ) : null}

            {actions ? actions : null}
          </HStack>
        </Stack>
      </Box>

      <Box flex={1} overflowY={"scroll"}>
        {table}
      </Box>

      {withPagination ? (
        <Box px={{ base: "4", md: "6" }} pb="5">
          <HStack spacing="3" justify="space-between">
            <Text color="fg.muted" textStyle="sm">
              Showing 1 to 5 of 42 results
            </Text>
            <ButtonGroup
              spacing="3"
              justifyContent="space-between"
              width={{ base: "full", md: "auto" }}
              variant="secondary"
            >
              <Button>Previous</Button>
              <Button>Next</Button>
            </ButtonGroup>
          </HStack>
        </Box>
      ) : null}
    </Flex>
  );
};
