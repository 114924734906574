import { useEffect, useState } from "react";

import { AppConfig } from "./app-config";

export const LoaderBeforeStaticData = ({ children }: { children: React.ReactNode }) => {
  const [staticDataLoaded, setStaticDataLoaded] = useState(false);
  useEffect(() => {
    AppConfig.staticDataPromise.then(() => setStaticDataLoaded(true));
  }, []);
  if (!staticDataLoaded) {
    return <p>Loading...</p>;
  }
  return <>{children}</>;
};
