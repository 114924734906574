import { Input, Select, Textarea } from "@chakra-ui/react";
import MultiSelect from "react-select";
import { EditableBaseCellProps, EditableField } from "./EditableField";

export const EditableInputField = ({
  type = "text",
  value,
  onEdit,
  ...cellProps
}: Omit<EditableBaseCellProps<string>, "ReadComponent" | "WriteComponent"> & {
  type?: React.HTMLInputTypeAttribute;
}) => (
  <EditableField
    value={value}
    {...cellProps}
    onEdit={onEdit}
    ReadComponent={({ value }) => <>{value || <i>vide</i>}</>}
    WriteComponent={({ value, setValue, inputRef }) => (
      <Input ref={inputRef} type={type} value={value} onChange={e => setValue(e.target.value)} />
    )}
  />
);

export const EditableSelectField = <T extends string | number>({
  value,
  options,
  onEdit,
  ...cellProps
}: Omit<EditableBaseCellProps<T>, "ReadComponent" | "WriteComponent"> & {
  options: { label: string; value: T }[];
}) => (
  <EditableField
    value={value}
    onEdit={onEdit}
    {...cellProps}
    ReadComponent={({ value }) => (
      <>{options.find(opt => value === opt.value)?.label || <i>vide</i>}</>
    )}
    WriteComponent={({ value, setValue, inputRef }) => (
      <Select ref={inputRef} value={value} onChange={e => setValue(e.target.value as T)}>
        {options.map(opt => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>
    )}
  />
);

export const EditableMultiSelectField = <T extends string | number>({
  options,
  value,
  onEdit,
  ...cellProps
}: Omit<EditableBaseCellProps<T[]>, "ReadComponent" | "WriteComponent"> & {
  options: { label: string; value: T }[];
}) => (
  <EditableField
    value={value}
    onEdit={onEdit}
    {...cellProps}
    ReadComponent={({ value }) => (
      <>
        {options
          .filter(opt => value.includes(opt.value))
          .map(opt => opt.label)
          .join(", ") || <i>vide</i>}
      </>
    )}
    WriteComponent={({ value, setValue, inputRef }) => (
      <MultiSelect
        isMulti
        ref={inputRef}
        value={options.filter(opt => value.includes(opt.value))}
        options={options}
        onChange={value => setValue(value.map(d => d.value))}
      />
    )}
  />
);

export const EditableTextAreaField = ({
  value,
  onEdit,
  ...cellProps
}: Omit<EditableBaseCellProps<string>, "ReadComponent" | "WriteComponent">) => (
  <EditableField
    value={value}
    {...cellProps}
    onEdit={onEdit}
    ReadComponent={({ value }) => <>{value || <i>vide</i>}</>}
    WriteComponent={({ value, setValue, inputRef }) => (
      <Textarea ref={inputRef} value={value} onChange={e => setValue(e.target.value)} />
    )}
  />
);
