import { ReactNode, ReactElement } from "react";
import { theme } from "@chakra-ui/pro-theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  Box,
  Flex,
  Stack,
  useColorModeValue as mode,
  ChakraProvider,
  Text,
  HStack,
  extendTheme,
  Grid,
} from "@chakra-ui/react";
import { BiCreditCard, BiTrash, BiRecycle, BiLogOut } from "react-icons/bi";
import { BsCaretRightFill } from "react-icons/bs";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import "@fontsource-variable/open-sans";
import "@fontsource-variable/spline-sans";
import { AppConfig } from "../app-config";

interface NavGroupProps {
  label: string;
  children: ReactNode;
}

interface NavItemProps {
  href?: string;
  label: string;
  subtle?: boolean;
  active?: boolean;
  icon: ReactElement;
  endElement?: ReactElement;
  children?: ReactNode;
  linkTo?: string;
  onClick?: () => void;
}

const NavItem = (props: NavItemProps) => {
  const navigate = useNavigate();
  const { active, subtle, icon, children, label, endElement, href, linkTo, onClick } = props;
  const match = useMatch(linkTo || "PROBABLY_NOT");
  const refinedActive = active || match;
  return (
    <HStack
      as="a"
      href={href}
      w="full"
      px="3"
      py="2"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.2s"
      bg={refinedActive ? "gray.700" : undefined}
      _hover={{ bg: "gray.700" }}
      _active={{ bg: "gray.600" }}
      onClick={
        onClick
          ? onClick
          : () => {
              linkTo ? navigate(linkTo) : undefined;
            }
      }
    >
      <Box fontSize="lg" color={refinedActive ? "currentcolor" : "gray.400"}>
        {icon}
      </Box>
      <Box flex="1" fontWeight="inherit" color={subtle ? "gray.400" : undefined}>
        {label}
      </Box>
      {endElement && !children && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>
  );
};

const NavGroup = (props: NavGroupProps) => {
  const { label, children } = props;
  return (
    <Box>
      <Text
        px="3"
        fontSize="xs"
        fontWeight="semibold"
        textTransform="uppercase"
        letterSpacing="widest"
        color="gray.500"
        mb="3"
      >
        {label}
      </Text>
      <Stack spacing="1">{children}</Stack>
    </Box>
  );
};

const queryClient = new QueryClient();

const proTheme = extendTheme(theme);
const extension = {
  colors: { ...proTheme.colors, brand: proTheme.colors.blue },
};
const dsitTheme = extendTheme(extension, proTheme);

export const OuterShell: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => (
  <ChakraProvider theme={dsitTheme}>{children}</ChakraProvider>
);

export const Shell = () => {
  return (
    <OuterShell>
      <QueryClientProvider client={queryClient}>
        <Outlet />
      </QueryClientProvider>
    </OuterShell>
  );
};

export const ShellOnlyBastien = () => {
  // Wow, much secure.
  if (AppConfig.publicClientApplication.getActiveAccount()?.username != "bastien@ouihelp.fr") {
    return null;
  }

  return (
    <Grid
      w="100vw"
      h="100vh"
      gridTemplateColumns={"256px 1fr"}
      gridTemplateRows={"1fr"}
      overflow={"hidden"}
    >
      <Box w="64" bg="gray.900" color="white" fontSize="sm">
        <Flex h="full" direction="column" px="4" py="4">
          <Stack spacing="8" flex="1" overflow="auto" pt="8">
            <NavGroup label="Stage 0">
              <NavItem icon={<BiTrash />} label="Payshits" linkTo="/2/ob/payshits" />
            </NavGroup>

            <NavGroup label="External">
              <NavItem icon={<BiCreditCard />} label="Microsoft" linkTo="/2/ob/microsoft" />
            </NavGroup>

            <NavGroup label="Actions">
              <NavItem icon={<BiRecycle />} label="Resync" linkTo="/2/ob/resync" />
              <NavItem
                icon={<BiRecycle />}
                label="Trigger Sentry"
                // @ts-expect-error: Intentional to trigger Sentry.
                onClick={() => iDoNotExist()}
              />
            </NavGroup>
          </Stack>

          <Box>
            <NavItem subtle icon={<BiLogOut />} label="Se déconnecter" onClick={AppConfig.logout} />
          </Box>
        </Flex>
      </Box>
      <Flex bg={mode("white", "gray.800")} p="6" overflow={"hidden"}>
        <Box overflow={"hidden"} width={"100%"}>
          <Outlet />
        </Box>
      </Flex>
    </Grid>
  );
};
