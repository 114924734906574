import { AppConfig } from "./app-config";

export const improvedFetch = async (
  path: string,
  options?: { isJson?: boolean; body?: any; method?: string; headers?: any }
): Promise<Response> => {
  const createSessionToken = await AppConfig.getCreateSessionAccessToken();
  const response = await fetch(`${AppConfig.baseUrl}${path}`, {
    headers: {
      Authorization: createSessionToken.accessToken,
      ...(options?.isJson === false ? {} : { "Content-Type": "application/json" }),
      ...(options?.headers || {}),
    },
    ...(options?.method ? { method: options.method } : {}),
    ...(options?.body ? { body: options.body } : {}),
  });

  if (response.status === 403) {
    AppConfig.logout();
  }

  return response;
};

export const improvedFetch2 = async (
  path: string,
  options?: { isJson?: boolean; body?: any; method?: string; headers?: any }
): Promise<Response> => {
  const createSessionToken = await AppConfig.getCreateSessionAccessToken();
  const response = await fetch(`${AppConfig.baseUrl}${path}`, {
    headers: {
      Authorization: `Bearer ${createSessionToken.accessToken}`,
      ...(options?.isJson === false ? {} : { "Content-Type": "application/json" }),
      ...(options?.headers || {}),
    },
    ...(options?.method ? { method: options.method } : {}),
    ...(options?.body ? { body: options.body } : {}),
  });

  if (response.status === 403) {
    AppConfig.logout();
  }

  return response;
};
