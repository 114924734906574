import {
  EditableInputField,
  EditableMultiSelectField,
  EditableSelectField,
  EditableTextAreaField,
} from "./EditableCustomFields";
import { EditableField as _EditableField } from "./EditableField";

export const EditableField = Object.assign(_EditableField, {
  input: EditableInputField,
  textArea: EditableTextAreaField,
  select: EditableSelectField,
  multiSelect: EditableMultiSelectField,
});
