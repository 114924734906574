import { GeoAreaType, FinancialHelpType } from "./types";

export const HelpLabel: Record<FinancialHelpType, string> = {
  [FinancialHelpType.APA]: "APA",
  [FinancialHelpType.CARSAT]: "CARSAT",
  [FinancialHelpType.CPAM]: "CPAM",
  [FinancialHelpType.PCH]: "PCH",
};

export const GeoAreaLabel: Record<GeoAreaType, string> = {
  [GeoAreaType.NATIONAL]: "national",
  [GeoAreaType.DEPARTMENTAL]: "départemental",
};
