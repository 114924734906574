import { Table as ChakraTable, Thead, Tbody, Td, Th, Tr } from "@chakra-ui/react";

import { flexRender, Table } from "@tanstack/react-table";

export function CustomTable<T>({ table, withSorting }: { table: Table<T>; withSorting?: boolean }) {
  return (
    <ChakraTable maxWidth={"100%"} size="sm" style={{ tableLayout: "fixed" }}>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th
                key={header.id}
                style={{ width: header.getSize() }}
                onClick={withSorting ? header.column.getToggleSortingHandler() : undefined}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
                {withSorting
                  ? {
                      asc: " 🔼",
                      desc: " 🔽",
                    }[header.column.getIsSorted() as string]
                  : null}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map(row => (
          <Tr key={row.id}>
            {row.getVisibleCells().map(cell => (
              <Td
                key={cell.id}
                style={{
                  width: cell.column.getSize(),
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
  );
}
