import { useEffect, useState } from "react";
import { lighten } from "polished";

import { improvedFetch } from "../../components/fetch";
import { Rythm, Title } from "../../components/Typography";

import { has_requirement, security_policy_is_valid, user_attributes_is_valid } from "permer_web";

const SUCESS_COLOR = lighten(0.6)("#2e7d32");
const ERROR_COLOR = lighten(0.45)("#d32f2f");
const DEFAULT_SECURITY_POLICY = '{"role:bar": ["bills:list:{zone}"]}';
const DEFAULT_USER_ATTRIBUTES = '["role:bar", "zone:lemans"]';
const DEFAULT_REQUIREMENT = "bills:list:bordeaux";

const hasRequirementBack = async (
  requirement: string,
  securityPolicy: string,
  userAttributes: string
): Promise<boolean> =>
  improvedFetch(`/permer`, {
    method: "POST",
    body: JSON.stringify({
      requirement,
      securityPolicy,
      userAttributes,
    }),
  }).then(async r => {
    const response = await r.json();
    return response.granted;
  });

const hasRequirement = (
  requirement: string,
  securityPolicy: string,
  userAttributes: string
): boolean => {
  return (
    securityPolicyIsValid(securityPolicy) &&
    userAttributesIsValid(userAttributes) &&
    has_requirement(requirement, securityPolicy, userAttributes)
  );
};

const securityPolicyIsValid = (securityPolicy: string): boolean => {
  return security_policy_is_valid(securityPolicy);
};

const userAttributesIsValid = (userAttributes: string): boolean => {
  return user_attributes_is_valid(userAttributes);
};

const SecurityPolicyPart = ({
  securityPolicy,
  setSecurityPolicyContent,
}: {
  securityPolicy: { content: string; valid: undefined | boolean };
  setSecurityPolicyContent: (s: string) => void;
}) => {
  let backgoundColor = undefined;
  switch (securityPolicy.valid) {
    case true:
      backgoundColor = SUCESS_COLOR;
      break;
    case false:
      backgoundColor = ERROR_COLOR;
      break;
  }
  return (
    <>
      <Rythm height={1.5}>
        <Title type="h2">Security Policy</Title>
      </Rythm>
      <textarea
        value={securityPolicy.content}
        style={{
          width: "100%",
          resize: "none",
          height: "180px",
          backgroundColor: backgoundColor,
          border: "1px solid black",
        }}
        onChange={e => setSecurityPolicyContent(e.target.value)}
      />
    </>
  );
};

const UserAttributesPart = ({
  userAttributes,
  setUserAttributesContent,
}: {
  userAttributes: { content: string; valid: undefined | boolean };
  setUserAttributesContent: (s: string) => void;
}) => {
  let backgoundColor = undefined;
  switch (userAttributes.valid) {
    case true:
      backgoundColor = SUCESS_COLOR;
      break;
    case false:
      backgoundColor = ERROR_COLOR;
      break;
  }
  return (
    <>
      <Rythm height={1.5}>
        <Title type="h2">User Attributes</Title>
      </Rythm>
      <textarea
        value={userAttributes.content}
        style={{
          width: "100%",
          resize: "none",
          height: "100px",
          backgroundColor: backgoundColor,
          border: "1px solid black",
        }}
        onChange={e => setUserAttributesContent(e.target.value)}
      />
    </>
  );
};

export const PermerSubapp = () => {
  const [securityPolicy, setSecurityPolicy] = useState<{
    content: string;
    valid: undefined | boolean;
  }>({
    content: DEFAULT_SECURITY_POLICY,
    valid: securityPolicyIsValid(DEFAULT_SECURITY_POLICY),
  });
  const setSecurityPolicyContent = (content: string) => {
    setSecurityPolicy({ content, valid: securityPolicyIsValid(content) });
  };

  const [userAttributes, setUserAttributes] = useState<{
    content: string;
    valid: undefined | boolean;
  }>({
    content: DEFAULT_USER_ATTRIBUTES,
    valid: userAttributesIsValid(DEFAULT_USER_ATTRIBUTES),
  });
  const setUserAttributesContent = (content: string) => {
    setUserAttributes({ content, valid: userAttributesIsValid(content) });
  };

  const [requirement, setRequirement] = useState<{
    content: string;
    valid: undefined | boolean;
  }>({
    content: DEFAULT_REQUIREMENT,
    valid: hasRequirement(DEFAULT_REQUIREMENT, securityPolicy.content, userAttributes.content),
  });
  const setRequirementContent = (content: string) => {
    setRequirement({
      content,
      valid: hasRequirement(content, securityPolicy.content, userAttributes.content),
    });
  };
  useEffect(() => {
    setRequirement(req => ({
      content: req.content,
      valid: hasRequirement(req.content, securityPolicy.content, userAttributes.content),
    }));
  }, [securityPolicy, userAttributes]);

  const [backGranted, setBackGranted] = useState<"Denied" | "Allowed" | "Computing" | "Invalid">(
    "Computing"
  );
  useEffect(() => {
    if (!(securityPolicy.valid && userAttributes.valid)) {
      setBackGranted("Invalid");
      return;
    }
    setBackGranted("Computing");
    hasRequirementBack(requirement.content, securityPolicy.content, userAttributes.content).then(
      granted => (granted ? setBackGranted("Allowed") : setBackGranted("Denied"))
    );
  }, [securityPolicy, userAttributes, requirement]);

  return (
    <>
      <SecurityPolicyPart
        securityPolicy={securityPolicy}
        setSecurityPolicyContent={setSecurityPolicyContent}
      />
      <UserAttributesPart
        userAttributes={userAttributes}
        setUserAttributesContent={setUserAttributesContent}
      />
      <Rythm height={1.5}>
        <Title type="h2">Requirement Test</Title>
      </Rythm>
      <div>
        <Rythm height={1.5}>
          <Title type="h3">Requirement</Title>
        </Rythm>
        <input
          style={{ width: "100%" }}
          value={requirement.content}
          onChange={e => setRequirementContent(e.target.value)}
        />
        <Rythm height={1.5}>
          <Title type="h3">Result (front)</Title>
        </Rythm>
        <span>
          {!(securityPolicy.valid && userAttributes.valid)
            ? "Invalid"
            : requirement.valid
              ? "Allowed"
              : "Denied"}
        </span>
        <Rythm height={1.5}>
          <Title type="h3">Result (back)</Title>
        </Rythm>
        <span>{backGranted}</span>
      </div>
    </>
  );
};
