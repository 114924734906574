import { FunctionComponent, PropsWithChildren, ReactNode } from "react";

import { FieldError, Merge } from "react-hook-form";

import { FormControl, FormLabel, FormHelperText, FormErrorMessage } from "@chakra-ui/react";

export const FormHelperTextCustom: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <FormHelperText color="blackAlpha.600">{children}</FormHelperText>
);

export const FormStepCustom = ({
  label,
  input,
  error,
  helpText,
}: {
  label: string;
  input: ReactNode;
  error: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  helpText?: ReactNode;
}) => (
  <FormControl isInvalid={!!error}>
    <FormLabel>{label}</FormLabel>
    {input}
    {error && error.message ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
    {helpText ? <FormHelperTextCustom>{helpText}</FormHelperTextCustom> : null}
  </FormControl>
);
