import { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, Row } from "react-table";

import { AppConfig, getAgencyNameFromUuid } from "../../components/app-config";
import { SyncTextButton } from "../../components/Button";

import { Data, DataEditableOnBlur } from "../../components/EditableData";
import { improvedFetch } from "../../components/fetch";
import { LoaderBeforeStaticData } from "../../components/LoaderBeforeStaticData";
import {
  EditableCell,
  EditableMultiCell,
  MainTable,
  NonEditableCell,
  Td,
  TdExpanded,
  Th,
  Tr,
  TrExpanded,
  TheadTr,
} from "../../components/Table";
import { Rythm, Title } from "../../components/Typography";

type EnrichedHohish = {
  uuid: string;
  pri: string;
  primary_mail_nickname: string;
  first_name: string;
  last_name: string;
  initial_primary_mail_nickname: string;
  initial_first_name: string;
  initial_last_name: string;
  job: string;
  agency_uuids: string[];
  first_day_date: string;
  last_day_date: string;
  google_workspace_id: string;
  microsoft_id: string;
  slack_id: string;
  gone: string;
};

const missingIDs = (h: EnrichedHohish) => !h.microsoft_id || !h.google_workspace_id || !h.slack_id;

const fetchUsers: () => Promise<Response> = () => improvedFetch(`/users`);

const editUser = (userId: string, data: Record<string, string | string[] | null>) => {
  return improvedFetch(`/users/${userId}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

const USERS_COLUMNS = [
  {
    Header: "Nickname",
    accessor: "primary_mail_nickname",
  },
  {
    Header: "Prénom",
    accessor: "first_name",
  },
  {
    Header: "Nom",
    accessor: "last_name",
  },
  {
    Header: "Job / Équipe",
    accessor: "job",
    Cell: (props: any) => (
      <EditableCell
        {...props}
        options={AppConfig.jobs}
        postNewValue={(newValue, updateData) =>
          editUser(props.row.original.uuid, { job: newValue }).then(async r => {
            if (!r.ok) {
              throw new Error("Error updating postal code");
            }
            const newUser = await r.json();
            updateData(newUser);
          })
        }
      />
    ),
  },
  {
    Header: "Agence(s)",
    accessor: "agency_uuids",
    sortType: (rowA: any, rowB: any, _columnId: any) => {
      return getAgencyNameFromUuid((rowA.values.agency_uuids || [])[0]) >=
        getAgencyNameFromUuid((rowB.values.agency_uuids || [])[0])
        ? 1
        : -1;
    },
    Cell: (props: any) => (
      <EditableMultiCell
        {...props}
        options={AppConfig.agenciesOptions}
        postNewValue={(newValue, updateData) =>
          editUser(props.row.original.uuid, { agency_uuids: newValue }).then(async r => {
            if (!r.ok) {
              throw new Error("Error updating agencies");
            }
            const newUser = await r.json();
            updateData(newUser);
          })
        }
      />
    ),
  },
  {
    Header: "Premier jour",
    accessor: "first_day_date",
    maxWidth: 120,
  },
  {
    Header: "Dernier jour",
    accessor: "last_day_date",
    maxWidth: 120,
  },
  {
    Header: "Missing IDs",
    accessor: (h: EnrichedHohish) => (missingIDs(h) ? "Yes" : ""),
  },
];

export const UserLine = ({ row }: { row: Row<EnrichedHohish> }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleActive = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Tr {...row.getRowProps()} onClick={toggleActive} expanded={expanded}>
        {row.cells.map(cell => {
          return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
        })}
      </Tr>
      {expanded ? (
        <TrExpanded>
          <TdExpanded colSpan={2}>
            <Rythm height={1.5}>
              <Data title={"DSIT ID"} value={row.original.uuid} />
            </Rythm>
            <Rythm height={1.5}>
              <Data title={"Microsoft ID"} value={row.original.microsoft_id} />
            </Rythm>
            <Rythm height={1.5}>
              <Data title={"Google ID"} value={row.original.google_workspace_id} />
            </Rythm>
            <Rythm height={1.5}>
              <Data title={"Slack ID"} value={row.original.slack_id} />
            </Rythm>
          </TdExpanded>
          <TdExpanded colSpan={2}>
            <Rythm height={1.5}>
              <DataEditableOnBlur
                title={`Nickname (init: ${row.original.initial_primary_mail_nickname})`}
                initialValue={row.original.primary_mail_nickname}
                postNewValueOnBlur={newValue =>
                  editUser(row.original.uuid, {
                    override_primary_mail_nickname: newValue,
                  })
                }
              />
            </Rythm>
            <Rythm height={1.5}>
              <DataEditableOnBlur
                title={`Prénom (init: ${row.original.initial_first_name})`}
                initialValue={row.original.first_name}
                postNewValueOnBlur={newValue =>
                  editUser(row.original.uuid, { override_first_name: newValue })
                }
              />
            </Rythm>
            <Rythm height={1.5}>
              <DataEditableOnBlur
                title={`Nom (init: ${row.original.initial_last_name})`}
                initialValue={row.original.last_name}
                postNewValueOnBlur={newValue =>
                  editUser(row.original.uuid, { override_last_name: newValue })
                }
              />
            </Rythm>
          </TdExpanded>
        </TrExpanded>
      ) : null}
    </>
  );
};

export const UsersSubapp = () => {
  const [users, setUsers] = useState<EnrichedHohish[]>([]);
  const [errorWhileFetching, setErrorWhileFetching] = useState(false);
  const [usersFilter, setUsersFilter] = useState<"active" | "active_missing_id" | "gone">("active");

  const updateUser = useMemo(() => {
    return (updatedUser: any) => {
      setUsers(oldUsers => {
        const newUsers = [...oldUsers];
        const updatedIndex = newUsers.findIndex(u => u.uuid === updatedUser.uuid);
        newUsers[updatedIndex] = updatedUser;
        return newUsers;
      });
    };
  }, [setUsers]);

  const shownUsers = useMemo(() => {
    let willShowUsers = users;
    if (usersFilter === "active") {
      willShowUsers = willShowUsers.filter(u => !u.gone);
    } else if (usersFilter === "active_missing_id") {
      willShowUsers = willShowUsers.filter(u => !u.gone && missingIDs(u));
    } else if (usersFilter === "gone") {
      willShowUsers = willShowUsers.filter(u => u.gone);
    }
    return willShowUsers;
  }, [users, usersFilter]);

  const tableInstance = useTable(
    {
      // @ts-expect-error: `USERS_COLUMNS` is badly typed
      columns: USERS_COLUMNS,
      data: shownUsers,
      defaultColumn: { maxWidth: 0, Cell: NonEditableCell },
      updateData: updateUser,
    },
    useSortBy
  );

  useEffect(() => {
    fetchUsers()
      .then(async usersResponse => {
        if (!usersResponse.ok) {
          throw new Error("Error while fetching");
        }
        setErrorWhileFetching(false);
        setUsers(await usersResponse.json());
      })
      .catch(() => {
        setErrorWhileFetching(true);
      });
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  if (users === null) {
    return <p>...</p>;
  }

  return (
    <LoaderBeforeStaticData>
      <Rythm height={2}>
        <Title type="h2">
          Ouihelpers
          {errorWhileFetching ? ` (error while fetching)` : ` (${shownUsers.length})`}
          {usersFilter === "active" ? (
            <>
              <SyncTextButton
                onClick={() => {
                  setUsersFilter("gone");
                }}
                style={{
                  marginLeft: "1em",
                }}
                text={"OHers partis"}
              />
              <SyncTextButton
                onClick={() => {
                  setUsersFilter("active_missing_id");
                }}
                style={{
                  marginLeft: "1em",
                }}
                text={"OHers actifs w missing IDs"}
              />
            </>
          ) : (
            <SyncTextButton
              onClick={() => {
                setUsersFilter("active");
              }}
              style={{
                marginLeft: "1em",
              }}
              text="OHers actifs"
            />
          )}
        </Title>
      </Rythm>

      <MainTable {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <TheadTr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      ...(column.maxWidth ? { width: column.maxWidth } : {}),
                    }}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span style={{ float: "right", fontStyle: "italic" }}>desc</span>
                      ) : (
                        <span style={{ float: "right", fontStyle: "italic" }}>asc</span>
                      )
                    ) : (
                      ""
                    )}
                  </Th>
                );
              })}
            </TheadTr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return <UserLine row={row} key={row.getRowProps().key} />;
          })}
        </tbody>
      </MainTable>
    </LoaderBeforeStaticData>
  );
};
