import { getAgencyNameFromUuid } from "./app-config";

function startsWithFilterFn(rows: any[], columnIds: string[], filterValue: unknown) {
  const search = String(filterValue).toLowerCase();
  rows = rows.filter(row => {
    return columnIds.some(id => {
      return String(row.values[id]).startsWith(search);
    });
  });
  return rows;
}

startsWithFilterFn.autoRemove = (val: any) => !val;

function agencyNameFromUuidFilterFn(rows: any[], columnIds: string[], filterValue: unknown) {
  const search = String(filterValue).toLowerCase();

  rows = rows.filter(row => {
    return columnIds.some(id => {
      return String(getAgencyNameFromUuid(row.values[id])).toLowerCase().includes(search);
    });
  });
  return rows;
}

agencyNameFromUuidFilterFn.autoRemove = (val: any) => !val;

const toYesNoString = (val: true | false | null) => {
  if (val === null) {
    return "";
  }
  return val ? "oui" : "non";
};

function yesNoFilterFn(rows: any[], columnIds: string[], filterValue: unknown) {
  const search = String(filterValue).toLowerCase();

  rows = rows.filter(row => {
    return columnIds.some(id => {
      return toYesNoString(row.values[id]).startsWith(search);
    });
  });
  return rows;
}

yesNoFilterFn.autoRemove = (val: any) => !val;

// It's not that bad...
// eslint-disable-next-line react-refresh/only-export-components
export const filterTypes = {
  startsWith: startsWithFilterFn,
  agencyNameFromUuid: agencyNameFromUuidFilterFn,
  yesNo: yesNoFilterFn,
};

export function DefaultColumnFilter({
  column: { filterValue, setFilter },
}: {
  column: { filterValue: any; setFilter: (value: any) => void };
}) {
  return (
    <input
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      onClick={e => e.stopPropagation()}
      style={{ overflow: "hidden", width: "100%" }}
    />
  );
}
