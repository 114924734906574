import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9ee771c0438b91308906450a02b33289@o44655.ingest.sentry.io/4506258639814656",
  environment: import.meta.env.PROD ? "production" : "development",
  integrations: [Sentry.browserTracingIntegration({}), Sentry.replayIntegration()],
  tracePropagationTargets: [
    "localhost",
    "aw423bq54hqyl434mlklojkfky0pphge.lambda-url.eu-west-3.on.aws",
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

import React from "react";
import ReactDOM from "react-dom/client";
import { AppRoot } from "./App";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AppRoot />
  </React.StrictMode>
);
