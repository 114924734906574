import { improvedFetch } from "../../components/fetch";
import { FetchError } from "../errors";
import { LegalEntity } from "./types";

export const getAllLegalEntites: () => Promise<LegalEntity[]> = () =>
  improvedFetch(`/legal-entities`).then(res => {
    if (!res.ok) {
      throw new FetchError();
    }
    return res.json();
  });
