import { LegalEntityUuid } from "../legal-entity/types";
import { Uuid } from "../types";

export enum GeoAreaType {
  NATIONAL = "national",
  DEPARTMENTAL = "departmental",
}

export enum FinancialHelpType {
  PCH = "pch",
  APA = "apa",
  CARSAT = "carsat",
  CPAM = "cpam",
}

export type FinancialHelpUuid = Uuid<"financial_help">;

export type FinancialHelp = {
  uuid: FinancialHelpUuid;
  name: string;
  help_type: FinancialHelpType;
  geo_area_type: GeoAreaType;
  legal_entity_uuid: LegalEntityUuid;
  departments: string[];
  week_amount_per_hour_cents: number | null;
  saturday_amount_per_hour_cents: number | null;
  sunday_amount_per_hour_cents: number | null;
  max_care_rate: number | null;
  min_gir: number;
  max_gir: number;
  slite_url: string | null;
  description: string | null;
  is_third_party_payer: boolean;
  created_at: string;
  updated_at: string;
};
