import * as z from "zod";
import isDate from "validator/es/lib/isDate";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import latinize from "latinize";

import { AppConfig, JobType } from "../../../components/app-config";

export const OnboardingSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  personalPhone: z
    .string()
    .transform((val: string) => val.replaceAll(" ", "").replaceAll(".", ""))
    .refine((val: string) => isMobilePhone(val, ["fr-FR"])),
  personalMail: z.string().email(),

  firstDayDate: z
    .string()
    .refine((val: string) => isDate(val, { format: "YYYY-MM-DD", strictMode: true })),
  job: z.string(),
  jobOther: z.optional(z.string()),
  agencies: z.array(z.string()),

  withTablet: z.boolean(),
  withXimi: z.boolean(),
  withPhone: z.boolean(),
  withData: z.boolean(),

  deliveryAddress: z.string().min(1),
  deliveryContact: z.string().min(1),

  more: z.string(),
});

export type OnboardingSchemaType = z.output<typeof OnboardingSchema>;

type GroupedJobOptionsType = {
  label: string;
  options: JobType[];
};

export const groupedJobOptionsPromises: Promise<GroupedJobOptionsType[]> =
  AppConfig.staticDataPromise.then(() => {
    const groupedJobOptions: Record<string, GroupedJobOptionsType> = {};
    AppConfig.jobs.forEach(job => {
      if (!job.assignable || job.group === "Autres") {
        return;
      }

      if (!groupedJobOptions[job.group]) {
        groupedJobOptions[job.group] = {
          label: job.group,
          options: [],
        };
      }

      groupedJobOptions[job.group].options.push(job);
    });

    const rv = Object.values(groupedJobOptions);

    rv.push({
      label: "Autres",
      options: [
        {
          value: "other",
          label: "Autre",
          group: "Autres",
          assignable: true,
        },
      ],
    });

    return rv;
  });

export const makeMail = (firstName: string | undefined, lastName: string | undefined): string => {
  if (!firstName || !lastName) {
    return "Prénom & nom requis";
  }
  const parts = [firstName.trim().replaceAll(" ", "-"), lastName.trim().replaceAll(" ", "")];
  return (
    parts
      .map((p: string) => p.toLowerCase())
      .map((p: string) => p.replaceAll("'", ""))
      .map((p: string) => latinize(p))
      .join(".") + "@ouihelp.fr"
  );
};
