import { useState } from "react";

import styled from "styled-components";
import Select from "react-select";

export const MainTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const Th = styled.th`
  text-align: left;
  padding: 4px;
`;

interface TdProps {
  readonly expanded?: boolean;
}

export const TheadTr = styled.tr`
  height: 31.5px;
`;

export const TheadTrWithFilters = styled.tr`
  height: 47.5px;
`;

export const Tr = styled.tr<TdProps>`
  height: 31px;
  border: 1px dashed black;
  border-bottom-color: ${props => (props.expanded ? "#ccc" : "black")};
`;

export const Td = styled.td`
  padding: 0;
`;

export const TrExpanded = styled.tr`
  border: 1px dashed black;
`;

export const TdExpanded = styled.td`
  padding: 0 4px;
`;

const NonEditableCellDiv = styled.div`
  height: 31px;
  padding: 8px 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Table = Object.assign(MainTable, {
  header: styled.thead({}),
  body: styled.tbody({}),
  headerRow: TheadTr,
  bodyRow: Tr,
  extendedBodyRow: TrExpanded,
  headerCell: Th,
  cell: Td,
});

export const NonEditableCell = ({ value }: { value: any }) => {
  // eslint-disable-next-line no-irregular-whitespace
  // " " with a non-breakable space is a hack to ensure the cell height
  // is respected.
  return <NonEditableCellDiv>{value}</NonEditableCellDiv>;
};

export const EditableCell = ({
  value: serverValue,
  postNewValue,
  options,
  isClearable,
  updateData,
}: {
  value: any;
  options: { label: string; value: string; assignable: boolean }[];
  postNewValue: (s: string | null, updateData: any) => Promise<Response>;
  isClearable?: boolean;
  updateData: any;
}) => {
  const [mode, setMode] = useState<"edit" | "view" | "network">("view");
  const [localValue, setLocalValue] = useState<string | null>(serverValue);

  const found = options.find(o => o.value === serverValue);
  const label = found?.label;

  if (mode !== "edit") {
    return (
      <div
        style={{ height: "100%", padding: "6px" }}
        onClick={e => {
          setMode("edit");
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {label || " "}
      </div>
    );
  }

  return (
    <div
      style={{ height: "100%" }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Select
        autoFocus
        isClearable={isClearable !== undefined ? isClearable : true}
        options={options.filter(o => o.assignable)}
        onBlur={() => setMode("view")}
        styles={{
          control: (provided, _state) => ({ ...provided, minHeight: 0 }),
          dropdownIndicator: (provided, _state) => ({
            ...provided,
            padding: 2,
          }),
          clearIndicator: (provided, _state) => ({
            ...provided,
            padding: 2,
          }),
        }}
        defaultValue={options.filter(option => option.value === localValue)[0]}
        onChange={newValue => {
          setLocalValue(newValue ? newValue.value : null);
          postNewValue(newValue ? newValue.value : null, updateData).then(
            () => {
              setMode("view");
            },
            () => {
              setLocalValue(newValue ? newValue.value : null);
              setMode("view");
            }
          );
        }}
      />
    </div>
  );
};

export const EditableMultiCell = ({
  value: serverValue,
  postNewValue,
  options,
  isClearable,
  updateData,
}: {
  value: any;
  options: { label: string; value: string; assignable: boolean }[];
  postNewValue: (s: string[], updateData: any) => Promise<Response>;
  isClearable?: boolean;
  updateData: any;
}) => {
  const [mode, setMode] = useState<"edit" | "view" | "network">("view");
  const [localValue, setLocalValue] = useState<string[]>(serverValue || []);
  const matchingOptions = options.filter(o => (serverValue || []).includes(o.value));
  const label = matchingOptions.map(m => m.label).join(", ");

  if (mode !== "edit") {
    return (
      <div
        style={{ height: "100%", padding: "6px" }}
        onClick={e => {
          setMode("edit");
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {label || " "}
      </div>
    );
  }

  return (
    <div
      style={{ height: "100%" }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Select
        isMulti
        autoFocus
        isClearable={isClearable !== undefined ? isClearable : true}
        options={options.filter(o => o.assignable)}
        onBlur={() => setMode("view")}
        styles={{
          control: (provided, _state) => ({ ...provided, minHeight: 0 }),
          dropdownIndicator: (provided, _state) => ({
            ...provided,
            padding: 2,
          }),
          clearIndicator: (provided, _state) => ({
            ...provided,
            padding: 2,
          }),
        }}
        defaultValue={options.filter(option => localValue.includes(option.value))}
        onChange={newValue => {
          setLocalValue(newValue.map(nv => nv.value));
          postNewValue(
            newValue.map(nv => nv.value),
            updateData
          ).then(
            () => {
              setMode("view");
            },
            () => {
              setLocalValue(serverValue || []);
              setMode("view");
            }
          );
        }}
      />
    </div>
  );
};

const YES_NO_OPTIONS = [
  { value: "true", label: "Oui", assignable: true },
  { value: "false", label: "Non", assignable: true },
];

export const EditableCellYesNo = ({
  value,
  postNewValue,
  updateData,
}: {
  value: true | false | null;
  postNewValue: (s: true | false, updateData: any) => Promise<Response>;
  updateData: any;
}) => {
  return (
    <EditableCell
      options={YES_NO_OPTIONS}
      value={value === null ? value : value.toString()}
      isClearable={false}
      postNewValue={(newValue: string | null, updateData: any) => {
        return postNewValue(newValue === "true" ? true : false, updateData);
      }}
      updateData={updateData}
    />
  );
};
