import { useTable, useSortBy } from "react-table";

import { AppConfig } from "../../components/app-config";
import { LoaderBeforeStaticData } from "../../components/LoaderBeforeStaticData";

import { MainTable, NonEditableCell, Td, Th, TheadTr, Tr } from "../../components/Table";
import { Rythm, Title } from "../../components/Typography";

const AGENCIES_COLUMNS = [
  {
    Header: "UUID",
    accessor: "agency_uuid",
    sortType: "string",
    maxWidth: 300,
  },
  {
    Header: "Zone slug",
    accessor: "zone_slug",
    sortType: "string",
    maxWidth: 200,
  },
  {
    Header: "Nom de l'agence",
    accessor: "agency_name",
    sortType: "string",
  },
];

const AgenciesSection = () => {
  const tableInstance = useTable(
    {
      // @ts-expect-error untyped `AGENCIES_COLUMNS`
      columns: AGENCIES_COLUMNS,
      data: AppConfig.agencies,
      defaultColumn: { maxWidth: 0, Cell: NonEditableCell },
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <Rythm height={1.5}>
        <Title type="h2">Agences</Title>
      </Rythm>
      <MainTable {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <TheadTr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      ...(column.maxWidth ? { width: column.maxWidth } : {}),
                    }}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span style={{ float: "right", fontStyle: "italic" }}>desc</span>
                      ) : (
                        <span style={{ float: "right", fontStyle: "italic" }}>asc</span>
                      )
                    ) : (
                      ""
                    )}
                  </Th>
                );
              })}
            </TheadTr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
                })}
              </Tr>
            );
          })}
        </tbody>
      </MainTable>
    </>
  );
};

const ZONES_COLUMNS = [
  {
    Header: "Zone slug",
    accessor: "zone_slug",
    sortType: "string",
    maxWidth: 200,
  },
  {
    Header: "Nom de la zone",
    accessor: "zone_name",
    sortType: "string",
  },
];

const ZonesSection = () => {
  const tableInstance = useTable(
    {
      // @ts-expect-error untyped `ZONES_COLUMNS`
      columns: ZONES_COLUMNS,
      data: AppConfig.zones,
      defaultColumn: { maxWidth: 0, Cell: NonEditableCell },
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <Rythm height={1.5}>
        <Title type="h2">Zones</Title>
      </Rythm>
      <MainTable {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <TheadTr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      ...(column.maxWidth ? { width: column.maxWidth } : {}),
                    }}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span style={{ float: "right", fontStyle: "italic" }}>desc</span>
                      ) : (
                        <span style={{ float: "right", fontStyle: "italic" }}>asc</span>
                      )
                    ) : (
                      ""
                    )}
                  </Th>
                );
              })}
            </TheadTr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
                })}
              </Tr>
            );
          })}
        </tbody>
      </MainTable>
    </>
  );
};

export const ZonesAndAgenciesSubapp = () => {
  return (
    <LoaderBeforeStaticData>
      <AgenciesSection />
      <Rythm height={2} />
      <ZonesSection />
    </LoaderBeforeStaticData>
  );
};
