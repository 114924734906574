import { z } from "zod";
import { GeoAreaType, FinancialHelpType } from "../../../services/financial-help";

export const HELP_SCHEMA = z.object({
  name: z.string().min(1, "La valeur est requise"),
  help_type: z.nativeEnum(FinancialHelpType, {
    invalid_type_error: "La valeur est requise",
  }),
  legal_entity_uuid: z.string({
    invalid_type_error: "La valeur est requise",
  }),
  geo_area_type: z.nativeEnum(GeoAreaType, {
    invalid_type_error: "La valeur est requise",
  }),
  departments: z.array(z.string()),
  week_amount_per_hour_cents: z.coerce
    .number()
    .positive("La valeur doit être positive")
    .nullable()
    .transform(n => n && n * 100),
  saturday_amount_per_hour_cents: z.coerce
    .number()
    .positive("La valeur doit être positive")
    .nullable()
    .transform(n => n && n * 100),
  sunday_amount_per_hour_cents: z.coerce
    .number()
    .positive("La valeur doit être positive")
    .nullable()
    .transform(n => n && n * 100),
  max_care_rate: z.coerce
    .number()
    .positive("La valeur doit être positive")
    .nullable()
    .transform(n => n && n * 100),
  min_gir: z.number({ invalid_type_error: "La valeur est requise" }),
  max_gir: z.number({ invalid_type_error: "La valeur est requise" }),
  slite_url: z.string(),
  description: z.string(),
  is_third_party_payer: z.boolean(),
});
