import { useState } from "react";
import styled from "styled-components";

export const TextButtonStyle = styled.a<{ running?: boolean }>`
  font-weight: normal;
  font-size: 12px;
  color: rgb(0, 0, 238);
  ${props =>
    props.running ? "color: grey; text-decoration-style: dotted; cursor: default;" : null};
`;

export const AsyncTextButton = ({
  onClick,
  text,
  style,
}: {
  onClick: () => Promise<void>;
  text: string;
  style?: any;
}) => {
  const [running, setRunning] = useState(false);
  return (
    <TextButtonStyle
      running={running}
      href="#fake-href"
      onClick={e => {
        if (!running) {
          e.preventDefault();
          setRunning(true);
          onClick().then(
            () => {
              setRunning(false);
            },
            () => {
              setRunning(false);
            }
          );
        }
      }}
      style={style}
    >
      {text}
    </TextButtonStyle>
  );
};

export const SyncTextButton = ({
  onClick,
  text,
  style,
}: {
  onClick: () => void;
  text: string;
  style?: any;
}) => {
  return (
    <TextButtonStyle
      href="#fake-href"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
      style={style}
    >
      {text}
    </TextButtonStyle>
  );
};
