import { FinancialHelpCreateData } from "./types";

export const DEFAULT_FINANCIAL_HELP_CREATE_DATA: FinancialHelpCreateData = {
  name: "",
  help_type: null,
  geo_area_type: null,
  legal_entity_uuid: null,
  departments: [],
  week_amount_per_hour_cents: null,
  saturday_amount_per_hour_cents: null,
  sunday_amount_per_hour_cents: null,
  max_care_rate: null,
  min_gir: null,
  max_gir: null,
  slite_url: "",
  description: "",
  is_third_party_payer: false,
};
