import { Button, Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { improvedFetch } from "../../../components/fetch";

const resyncFetch = () => {
  return improvedFetch(`/resync`, {
    method: "POST",
  });
};

export const Resync = () => {
  const resyncMutation = useMutation({
    mutationFn: () => {
      return resyncFetch().then(r => {
        if (!r.ok) {
          throw new Error("Non-OK response");
        }
      });
    },
  });

  return resyncMutation.isSuccess ? (
    <Text textStyle="sm">Tout bon, la resync a réussi.</Text>
  ) : (
    <Button isLoading={resyncMutation.isPending} onClick={() => resyncMutation.mutate()}>
      Resync
    </Button>
  );
};
