import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import { switchProp } from "styled-tools";

const lhCrop = (lineHeight: number) => css`
  &::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((${lineHeight} - 1) * 0.5em * -1);
  }

  &::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-bottom: calc((${lineHeight} - 1) * 0.5em * -1);
  }
`;

type TypographySizes = "small" | "medium";
type TypographyProps = {
  size?: TypographySizes;
  color?: string;
};

const TypographyStyle = css`
  ${lhCrop(1.5)};
  line-height: 1.5;
  ${switchProp("size", {
    medium: css`
      font-size: ${rem(14)};
    `,
    small: css`
      font-size: ${rem(12)};
    `,
  })}
`;

export const Typography: React.FC<PropsWithChildren<TypographyProps>> = styled(
  // It's not really unused I think, it's poped out of `props` this way.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ size = "medium", ...props }: PropsWithChildren<TypographyProps>) =>
    React.createElement("p", props)
)`
  ${TypographyStyle}
`;

type TitleType = "h1" | "h2" | "h3" | "h4";
type TitleProps = {
  type: TitleType;
};

const TitleStyle = css`
  ${lhCrop(1)};
  line-height: 1;
  margin: 0;
  ${switchProp("type", {
    h1: css`
      font-size: ${rem(32)};
    `,
    h2: css`
      font-size: ${rem(24)};
    `,
    h3: css`
      font-size: ${rem(20)};
    `,
    h4: css`
      font-size: ${rem(18)};
    `,
  })}
`;

export const Title: React.FC<PropsWithChildren<TitleProps>> = styled(
  ({ type, ...props }: PropsWithChildren<TitleProps>) => React.createElement(type, props)
)`
  ${TitleStyle}
`;

export const Rythm = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  height: ${props => `${props.height * 32}px`};
`;
