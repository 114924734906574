import { improvedFetch } from "../../components/fetch";
import { FetchError } from "../errors";
import { Department as Department } from "./types";

export const getAllDepartments: () => Promise<Department[]> = () =>
  improvedFetch(`/departments`).then(res => {
    if (!res.ok) {
      throw new FetchError();
    }
    return res.json();
  });
