import { useState } from "react";
import { Box, Container, Button, VStack, Spacer, ButtonGroup, Text } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { improvedFetch } from "../../../components/fetch";
import { Dropzone } from "../../../components/2/dropzone";
import { useNavigate } from "react-router-dom";
import { LayoutTable } from "../../../components/2/layout-table";
import { CustomTable } from "../../../components/2/custom-table";

const importPayshits = (file: File) => {
  const formData = new FormData();
  formData.append("payshitExport", file);
  return improvedFetch(`/payshits/imports`, {
    isJson: false,
    method: "POST",
    body: formData,
  });
};

type Payshit = {
  id: string;
  last_name: string;
  birth_name: string;
  first_name: string;
  birth_date: string;
  first_day_date: string;
  last_day_date: string;
  end_contract_date: string;
};

const getPayshits = async (): Promise<Payshit[]> => {
  const response = await improvedFetch(`/payshits`);
  return await response.json();
};

export const PayshitsImport = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const submitFile = useMutation({
    mutationFn: (selectedFile: File) => {
      return importPayshits(selectedFile);
    },
  });

  return submitFile.isSuccess ? (
    <Text textStyle="sm">Tout bon, l'import a réussi.</Text>
  ) : (
    <Box as="section" bg="bg.surface" py={{ base: "4", md: "8" }}>
      <Container maxW="lg">
        <VStack spacing={4} align="stretch">
          <Dropzone selectedFile={selectedFile} onSelectFile={setSelectedFile} />

          <ButtonGroup variant="outline" spacing="6" w="100%">
            <Button
              isDisabled={!selectedFile}
              onClick={() => setSelectedFile(null)}
              colorScheme="gray"
            >
              Réinitialiser
            </Button>
            <Spacer />
            <Button
              isDisabled={!selectedFile}
              isLoading={submitFile.isPending}
              onClick={() => selectedFile && submitFile.mutate(selectedFile)}
            >
              Envoyer
            </Button>
          </ButtonGroup>
        </VStack>
      </Container>
    </Box>
  );
};

const columnHelper = createColumnHelper<Payshit>();

const columns = [
  columnHelper.accessor("id", {
    header: "ID",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("last_name", {
    header: "Nom",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("birth_name", {
    header: "Nom de naissance",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("first_name", {
    header: "Prénom",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("birth_date", {
    header: "Date de naissance",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("first_day_date", {
    header: "Premier jour",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("last_day_date", {
    header: "Dernier jour",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("end_contract_date", {
    header: "Fin de contrat",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
];

export const Payshits = () => {
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ["payshits"],
    queryFn: () => getPayshits(),
  });

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnVisibility: {
        birth_name: false,
      },
      columnSizing: {
        id: 100,
      },
    },
  });

  return (
    <LayoutTable
      title="Payshits"
      table={<CustomTable table={table} />}
      actions={
        <Button variant={"outline"} onClick={() => navigate("/2/ob/payshits/import")}>
          Import
        </Button>
      }
    />
  );
};
