import { useState, useRef, useEffect, useCallback } from "react";
import { Button, Center, HStack, Icon, Square, Text, VStack } from "@chakra-ui/react";
import { FiUploadCloud } from "react-icons/fi";

/**
 * https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c
 */
const formatBytes = (bytesCount: number, decimals?: number): string => {
  if (bytesCount == 0) return "0 Bytes";
  const k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytesCount) / Math.log(k));
  return parseFloat((bytesCount / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * This is mostly https://blog.alexdevero.com/react-file-dropzone/.
 */
export const Dropzone = ({
  onSelectFile,
  selectedFile,
}: {
  onSelectFile?: (f: File) => void;
  selectedFile?: File | null;
}) => {
  const dropRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragActive, setIsDragActive] = useState<boolean>(false);

  const handleDragIn = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer?.items && event.dataTransfer.items.length > 0) {
      setIsDragActive(true);
    }
  }, []);

  const handleDragOut = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragActive(false);
  }, []);

  const handleDrag = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (!isDragActive) {
        setIsDragActive(true);
      }
    },
    [isDragActive]
  );

  // Create handler for drop event:
  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setIsDragActive(false);

      if (event.dataTransfer?.files && event.dataTransfer.files.length === 1) {
        const files = event.dataTransfer.files;
        onSelectFile?.(files[0]);
      }
    },
    [onSelectFile]
  );

  // Attach listeners to dropzone on mount:
  useEffect(() => {
    const tempZoneRef = dropRef?.current;
    if (tempZoneRef && !selectedFile) {
      tempZoneRef.addEventListener("dragenter", handleDragIn);
      tempZoneRef.addEventListener("dragleave", handleDragOut);
      tempZoneRef.addEventListener("dragover", handleDrag);
      tempZoneRef.addEventListener("drop", handleDrop);
    }

    // Remove listeners from dropzone on unmount:
    return () => {
      tempZoneRef?.removeEventListener("dragenter", handleDragIn);
      tempZoneRef?.removeEventListener("dragleave", handleDragOut);
      tempZoneRef?.removeEventListener("dragover", handleDrag);
      tempZoneRef?.removeEventListener("drop", handleDrop);
    };
  }, [selectedFile, handleDragIn, handleDragOut, handleDrag, handleDrop]);

  return (
    <Center
      borderWidth="1px"
      borderRadius="lg"
      borderStyle={isDragActive ? "solid" : "dashed"}
      px="6"
      py="4"
      ref={dropRef}
    >
      <VStack spacing="3">
        <Square size="10" bg="bg.subtle" borderRadius="lg">
          <Icon as={FiUploadCloud} boxSize="5" color="fg.muted" />
        </Square>
        <VStack spacing="1">
          <HStack spacing="1" whiteSpace="nowrap">
            {selectedFile ? (
              <Text textStyle="sm" color="fg.muted">
                {selectedFile.name} ({formatBytes(selectedFile.size)})
              </Text>
            ) : (
              <>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={e => {
                    if (e.target.files) {
                      onSelectFile?.(e.target.files[0]);
                    }
                  }}
                />
                <Button
                  variant="text"
                  colorScheme="blue"
                  size="sm"
                  onClick={() => fileInputRef.current?.click()}
                >
                  Sélectionner un fichier
                </Button>
                <Text textStyle="sm" color="fg.muted">
                  ou glisser-déposer dans cette zone.
                </Text>
              </>
            )}
          </HStack>
        </VStack>
      </VStack>
    </Center>
  );
};
