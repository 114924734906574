import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { TextButtonStyle } from "../../../components/Button";
import { LoaderBeforeStaticData } from "../../../components/LoaderBeforeStaticData";
import { Table } from "../../../components/Table";
import { Rythm, Title } from "../../../components/Typography";
import { useGetAllDepartments } from "../../../services/department";
import { FinancialHelp, putFinancialHelp } from "../../../services/financial-help";
import { useGetAllHelps } from "../../../services/financial-help";
import { useGetAllLegalEntities } from "../../../services/legal-entity";
import { FinancialHelpTableHeader } from "./FinancialHelpTableHeader";
import { FinancialHelpTableRow } from "./FinancialHelpTableRow";
import { FinancialHelpFilters } from "./types";

export const FinancialHelpTable = () => {
  const [helps, setHelps] = useGetAllHelps();
  const [departments] = useGetAllDepartments();
  const [legalEntities] = useGetAllLegalEntities();
  const [filters, setFilters] = useState<FinancialHelpFilters>({});

  if (helps.isLoading || departments.isLoading || legalEntities.isLoading)
    return <div>Chargement...</div>;

  if (helps.isError || departments.isError || legalEntities.isError)
    return <div>Une erreur est survenue :(</div>;

  // filter data according to table headers inputs values
  const filteredHelps = helps.data
    .filter(c => !filters.name || c.name.toLowerCase().includes(filters.name.toLowerCase()))
    .filter(c => !filters.help_type || c.help_type === filters.help_type)
    .filter(c => !filters.legal_entity_uuid || c.legal_entity_uuid === filters.legal_entity_uuid)
    .filter(c => !filters.geo_area_type || c.geo_area_type === filters.geo_area_type)
    .filter(c => !filters.departments || c.departments.includes(filters.departments));

  const onCommitEdition = (uuid: string, changes: Partial<FinancialHelp>) => {
    // commit locally anyway
    setHelps({
      ...helps,
      data: helps.data.map(help => (help.uuid === uuid ? { ...help, ...changes } : help)),
    });
    // send a request to server to update data
    // and don't expect an answer

    // They're not really unused, they're pop-ed from `data` this way.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { created_at, updated_at, ...data } = helps.data.find(
      help => help.uuid === uuid
    ) as FinancialHelp;
    putFinancialHelp({ ...data, ...changes });
  };

  return (
    <LoaderBeforeStaticData>
      <Rythm height={2}>
        <Box display="flex" gap={8} alignItems="end">
          <Title type="h2">Aides financière ({filteredHelps.length})</Title>
          <TextButtonStyle href="/financial-helps/create">Créer une nouvelle aide</TextButtonStyle>
        </Box>
      </Rythm>
      <Table>
        <FinancialHelpTableHeader
          filters={filters}
          setFilters={setFilters}
          departments={departments.data}
          legalEntities={legalEntities.data}
        />
        <Table.body>
          {filteredHelps.map(help => (
            <FinancialHelpTableRow
              key={help.uuid}
              datum={help}
              onEdit={onCommitEdition}
              departments={departments.data}
              legalEntities={legalEntities.data}
            />
          ))}
        </Table.body>
      </Table>
    </LoaderBeforeStaticData>
  );
};
