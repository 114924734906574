import { useEffect, useState } from "react";
import { QueryResult } from "./types";

/**
 * behold, this low cost react-query hook does not handle :
 * - refresh
 * - keys
 * @param request request to trigger
 * @returns a query result
 */
export const queryGenerator =
  <In extends Array<unknown>, Out>(request: (...args: In) => Promise<Out>) =>
  (...args: In) => {
    const [query, setQuery] = useState<QueryResult<Out>>({
      isLoading: true,
      isError: false,
      isSuccess: false,
    });

    useEffect(() => {
      request(...args)
        .then(res =>
          setQuery({
            isLoading: false,
            isError: false,
            isSuccess: true,
            data: res,
          })
        )
        .catch((err: any) => {
          setQuery({
            isLoading: false,
            isError: true,
            isSuccess: false,
            error: err,
          });
        });
      // No idea if it's a real issue.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [query, setQuery] as [
      QueryResult<Out>,
      React.Dispatch<React.SetStateAction<QueryResult<Out>>>,
    ];
  };
