import { useQuery } from "@tanstack/react-query";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { improvedFetch } from "../../../components/fetch";
import { LayoutTable } from "../../../components/2/layout-table";
import { CustomTable } from "../../../components/2/custom-table";
import { useState } from "react";

type Microsoft = {
  id: string;
  upn: string;
  license_skus: string;
  last_login_at: string;
  last_noninteractive_login_at: string;
  created_at: string;
  disappeared_at: string;
  linked_to_hohish: boolean;
  linked_to_active_hohish: boolean;
};

const getMicrosoft = async (): Promise<Microsoft[]> => {
  const response = await improvedFetch(`/microsoft`);
  return await response.json();
};

const columnHelper = createColumnHelper<Microsoft>();

const columns = [
  columnHelper.accessor("id", {
    header: "ID",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("upn", {
    header: "UPN",
    cell: info => info.getValue(),
    footer: info => info.column.id,
    minSize: 230,
  }),
  columnHelper.accessor("license_skus", {
    header: "Licenses",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("last_login_at", {
    header: "Dernière connexion",
    cell: info => info.getValue(),
    footer: info => info.column.id,
    minSize: 230,
  }),
  columnHelper.accessor("last_noninteractive_login_at", {
    header: "Dernière connexion non-interactive",
    cell: info => info.getValue(),
    footer: info => info.column.id,
    minSize: 230,
  }),
  columnHelper.accessor("created_at", {
    header: "Créé à",
    cell: info => info.getValue(),
    footer: info => info.column.id,
    minSize: 230,
  }),
  columnHelper.accessor("linked_to_hohish", {
    header: "Hohish connu ?",
    cell: info => (info.getValue() ? "Oui" : "Non"),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("linked_to_active_hohish", {
    header: "Hohish actif ?",
    cell: info => (info.getValue() ? "Oui" : "Non"),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("disappeared_at", {
    header: "Disparu à",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
];

export const Microsoft = () => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data } = useQuery({
    queryKey: ["microsoft"],
    queryFn: () => getMicrosoft(),
  });

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      columnSizing: {
        id: 100,
      },
    },
    debugTable: true,
  });

  return <LayoutTable title="Microsoft" table={<CustomTable table={table} withSorting={true} />} />;
};
